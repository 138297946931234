import { SxProps } from '@mui/material';
import { colors } from '@coverright/ui/themes';
import {
  Benefit,
  DoctorCoverageList,
  TooltipedTitle,
} from '@coverright/ui/marketplaces';
import { DrugCoverageList } from '@coverright/ui/marketplaces';
import {
  Box,
  Button,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import {
  GetDefaultPlansYear,
  getGapTooltipByTier,
  getInitialTooltipByTier,
  getQuoteId
} from '@coverright/utils';
import CompareHeaderCard from './CompareHeaderCard';
import paths from '../../../../config/router-paths';
import { useNavigate } from 'react-router-dom';
import { ListPlanDetailText } from '../../../components/ListPlanDetail';
import * as Scroll from 'react-scroll';
import { planFiles } from '../../../utils/utils';
import TableScrollElement from './TableScrollElement';
import { useResizeDetector } from 'react-resize-detector';
import { PlanWithTiers } from '@coverright/data-access/medicare';
import {
  DrugTier,
  DrugTierCoverageOutput,
  DrugTierOutput,
} from '@coverright/data-access/types/medicare';
import { QuoteContext } from '@coverright/shared/contexts';
import { getDeductible } from '@coverright/shared/config';
import { MaDrugCostBreakdownModal } from '@coverright/widgets';

const Link = Scroll.Link;

type ComparisonTableProps = {
  plans: PlanWithTiers[];
  zip: string;
  countyName: string;
};

const styles: (count: number) => SxProps = (count: number) => ({
  mx: { xs: '-8px', md: 0 },
  position: 'relative',
  overflowX: { xs: 'auto', md: 'unset' },
  '& table': {
    tableLayout: 'fixed',
    width: '100%',
    borderSpacing: 0,
    letterSpacing: '-1px',
    '& tbody': {
      '& li': {
        fontSize: 12,
        lineHeight: '15px',
      },
      '& tr td': {
        width: 150,
        '&:first-child': {
          position: 'sticky',
          left: 0,
          zIndex: 1,
          background: 'white',
          width: 170,
        },
      },
      '& tr': {
        '&.table-header': {
          '& td:not(:first-child)': {
            borderRight: '1px solid #E8ECED',
          },
          '& td': {
            paddingTop: '41px',
          },
          position: 'sticky',
          zIndex: 3,
          top: { xs: 0, md: 41 },
          boxShadow: '0px 5px 5px rgba(28, 67, 79, 0.15)',
        },
        '& td:first-child': {
          padding: '10px 16px',
          '&:after': {
            boxShadow: '10px 0px 10px -10px rgba(0, 0, 0, 0.15) inset',
            content: '" "',
            height: '100%',
            position: 'absolute',
            top: '0',
            right: '-15px',
            width: '15px',
          },
        },
        '& td:not(:first-child)': {
          padding: '10px 30px',
        },
      },
      '& tr:not(.subheader):not(.header):not(.drug-subheader):not(.drug-blue-subheader)':
        {
          '&:not(:first-child) td': {
            padding: '17px 16px',
            '&:not(:first-child)': {
              padding: '17px 30px',
            },
          },
          '& td': {
            verticalAlign: 'top',
            borderBottom: '1px solid #E8ECED',
            background: 'white',
          },
        },
      '& tr:not(.header)': {
        '& td:first-child': {
          '&:before': {
            boxShadow: '-10px 0px 10px -10px rgba(0, 0, 0, 0.15) inset',
            background: '#f5f7f6',
            content: '" "',
            height: '100%',
            top: '0',
            left: '-15px',
            position: 'absolute',
            width: '15px',
          },
        },
      },
      '& tr.header': {
        '& td': {
          background: colors.custom.green.variant2,
          color: 'white',
          fontSize: 16,
          lineHeight: '20px',
          fontWeight: 700,
          height: '33px',
          position: 'sticky',
          top: 169,
          zIndex: 2,
          '&:first-child': {
            zIndex: 3,
          },
        },
      },
      '& tr.subheader': {
        '& td': {
          background: '#F2F9F9',
          fontSize: 14,
          lineHeight: '17px',
          fontWeight: 500,
          height: '45px',
          color: colors.custom.green.variant2,
        },
      },
      '& tr.drug-subheader': {
        '& td': {
          background: '#F4F6F6',
        },
        '& td:first-child': {
          fontSize: 18,
          lineHeight: '22px',
          fontWeight: 700,
        },
        '& td:not(:first-child)': {
          fontSize: 14,
          lineHeight: '17px',
          fontWeight: 400,
        },
      },
      '& tr.drug-blue-subheader': {
        '& td': {
          background: '#F7FAFE',
        },
        '& td:first-child': {
          fontSize: 14,
          lineHeight: '17px',
          fontWeight: 400,
        },
      },
    },
  },
});

const ComparisonTable = (props: ComparisonTableProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const { preferredDrugs, planYear } = React.useContext(QuoteContext);
  const [plan, setPlan] = React.useState<PlanWithTiers | undefined>();
  const [modalOpen, setModalOpen] = React.useState(false);
  const { height, ref } = useResizeDetector();


  const plans = React.useMemo(() => {
    if (!props.plans) {
      return [];
    }
    return props.plans;
  }, [props.plans, phone]);

  const is2025 = plans[0]?.planYear?.toString() === 'Year2025';

  const getValuesByKey = React.useCallback(
    (
      key: keyof PlanWithTiers,
      lastValue?: any,
      mapFunction?: (value: any, plan: PlanWithTiers) => any
    ) => {
      let result: any[];
      if (!plans) {
        return [];
      }
      if (!plans.length) {
        result = [''];
      } else {
        result = plans.map((p) => p[key]);
      }
      if (mapFunction) {
        result = result.map((v, index) => mapFunction(v, plans[index]));
      }
      if (lastValue) {
        result.push(lastValue);
      }
      return result;
    },
    [plans]
  );

  const catastrophicCoverage: DrugTier[] = React.useMemo(() => {
    return plans.reduce((previousValue: DrugTier[], plan: PlanWithTiers) => {
      if (
        plan?.drugDetails?.catastrophicCoverage[0].items?.length &&
        plan?.drugDetails?.catastrophicCoverage[0].items?.length >
          previousValue.length
      ) {
        return plan?.drugDetails?.catastrophicCoverage[0].items;
      }
      return previousValue;
    }, [] as DrugTier[]);
  }, [plans]);

  const preferred30 = React.useMemo(() => {
    return plans.reduce(
      (previousValue: DrugTierCoverageOutput[], plan: PlanWithTiers) => {
        if (
          (plan?.drugsTiers30Preferred?.initialCoverageDrugTiers?.length || 0) >
          previousValue.length
        ) {
          return plan?.drugsTiers30Preferred
            ?.initialCoverageDrugTiers as DrugTierCoverageOutput[];
        }
        return previousValue;
      },
      [] as DrugTierCoverageOutput[]
    );
  }, [plans]);

  const preferred30Gap = React.useMemo(() => {
    return preferred30.filter((tier, i) =>
      plans
        .map((p) => p.drugsTiers30Preferred?.coverageGapDrugTiers[i]?.coverage)
        .some((v) => !!v && v !== '25% coinsurance')
    );
  }, [preferred30]);

  const standard30 = React.useMemo(() => {
    return plans.reduce(
      (previousValue: DrugTierCoverageOutput[], plan: PlanWithTiers) => {
        if (
          (plan?.drugsTiers30Standard?.initialCoverageDrugTiers?.length || 0) >
          previousValue.length
        ) {
          return plan?.drugsTiers30Standard
            ?.initialCoverageDrugTiers as DrugTierCoverageOutput[];
        }
        return previousValue;
      },
      [] as DrugTierCoverageOutput[]
    );
  }, [plans]);

  const standard30Gap = React.useMemo(() => {
    return standard30.filter((tier, i) =>
      plans
        .map((p) => p.drugsTiers30Standard?.coverageGapDrugTiers[i]?.coverage)
        .some((v) => !!v && v !== '25% coinsurance')
    );
  }, [standard30]);

  const preferred90 = React.useMemo(() => {
    return plans.reduce(
      (previousValue: DrugTierCoverageOutput[], plan: PlanWithTiers) => {
        if (
          (plan?.drugsTiers90Preferred?.initialCoverageDrugTiers?.length || 0) >
          previousValue.length
        ) {
          return plan?.drugsTiers90Preferred
            ?.initialCoverageDrugTiers as DrugTierCoverageOutput[];
        }
        return previousValue;
      },
      [] as DrugTierCoverageOutput[]
    );
  }, [plans]);

  const preferred90Gap = React.useMemo(() => {
    return preferred90.filter((tier, i) =>
      plans
        .map((p) => p.drugsTiers90Preferred?.coverageGapDrugTiers[i]?.coverage)
        .some((v) => !!v && v !== '25% coinsurance')
    );
  }, [preferred90]);

  const standard90 = React.useMemo(() => {
    return plans.reduce(
      (previousValue: DrugTierCoverageOutput[], plan: PlanWithTiers) => {
        if (
          (plan?.drugsTiers90Standard?.initialCoverageDrugTiers?.length || 0) >
          previousValue.length
        ) {
          return plan?.drugsTiers90Standard
            ?.initialCoverageDrugTiers as DrugTierCoverageOutput[];
        }
        return previousValue;
      },
      [] as DrugTierCoverageOutput[]
    );
  }, [plans]);

  const standard90Gap = React.useMemo(() => {
    return standard90.filter((tier, i) =>
      plans
        .map((p) => p.drugsTiers90Standard?.coverageGapDrugTiers[i]?.coverage)
        .some((v) => !!v && v !== '25% coinsurance')
    );
  }, [standard90]);

  const gapTiers = React.useMemo(() => {
    return [
      preferred30Gap,
      standard90Gap,
      preferred90Gap,
      standard30Gap,
    ].reduce((previousValue, currentValue) => {
      if (currentValue.length > previousValue.length) {
        return currentValue;
      }
      return previousValue;
    }, []);
  }, [preferred30Gap, standard90Gap, preferred90Gap, standard30Gap]);

  const allDSNP = React.useMemo(() => {
    return plans.every((p) => p.SNPType === 'D_SNP');
  }, [plans]);

  if (!plans) {
    return null;
  }

  return (
    <Box
      sx={{
        ...styles(plans.length + 1),
        overflowY: { xs: 'auto', md: 'unset' },
        maxHeight: { xs: '100vh', md: 'unset' },
      }}
    >
      {plan?.bidId && (
        <MaDrugCostBreakdownModal
          bidId={plan.bidId}
          open={modalOpen}
          onClose={() => {
            setPlan(undefined);
            setModalOpen(false);
          }}
          countyName={props.countyName}
          zip={props.zip}
          planYear={planYear || GetDefaultPlansYear()}
          quoteId={getQuoteId()}
        />
      )}
      <table>
        <tbody>
          <Box
            component={'tr'}
            sx={{ height: '1px' }}
            className={'table-header'}
            ref={ref}
          >
            <td>
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'flex-start'}
                minWidth={'150px'}
              >
                <Typography className={'bold fs-20'}>Compare plans</Typography>
                {/*<div>
                  <div className={"h-12"}/>
                  <div className={'pointer'}>
                    <img src={"/assets/img/message.svg"}/>
                    <div>Email me this comparison</div>
                  </div>
                </div>*/}
              </Box>
            </td>
            {plans.map((plan) => (
              <Box component={'td'} sx={{ height: 'inherit' }} key={plan.bidId}>
                <CompareHeaderCard plan={plan} />
              </Box>
            ))}
            <td>
              <Typography className={'bold fs-20'}>
                Original Medicare Part A/Part B
              </Typography>
            </td>
          </Box>
        </tbody>
        <TableScrollElement name={'Key Plan Benefits Overview'}>
          <tr className={'header'}>
            <td style={{ top: (height || 0) + 41 }}>
              Key Plan Benefits Overview
            </td>
            <td
              style={{ top: (height || 0) + 41 }}
              colSpan={plans.length + 1}
            ></td>
          </tr>
          <tr className={'subheader'}>
            <td>
              <Subheader
                title={'Estimated Monthly Cost Summary'}
                img={'/assets/img/expenses.svg'}
              />
            </td>
            <td colSpan={plans.length + 1}></td>
          </tr>
          <PlanDetailRow
            title={'Monthly plan premium'}
            tooltip={
              'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'
            }
            values={getValuesByKey(
              'monthlyPremium',
              <div>
                <Typography variant={'h5'}>Part A: Typically $0.00</Typography>
                <Typography variant={'h5'}>
                  Part B: Typically{' '}
                  {getDeductible('standardPartBMonthlyPremium', planYear)}
                </Typography>
              </div>
            )}
          />
          <PlanDetailRow
            title={'Estimated drug costs'}
            tooltip={
              'Estimated monthly drug cost includes any annual out-of-pocket cost sharing expenses that you must pay for the medications entered, calculated on an average monthly basis. Cost sharing expenses are based on the individual drug tier and the drug coverage breakdown as outlined in the plan details. The retail reference price (where applicable) for your drugs is based on average drug prices in the plan’s local area. Estimated monthly drug costs can only be calculated if you have entered medication information.'
            }
            values={getValuesByKey('drugsCost', 'Not covered')}
          />
          <PlanDetailRow
            title={'Estimated doctor costs'}
            tooltip={
              'Estimated doctor costs are calculated based on your estimated number of Primary Care Physician and Specialist visits per year, multiplied by the copays or coinsurance for in-network providers.'
            }
            values={getValuesByKey('doctorsCost', 'Not covered')}
          />
          <PlanDetailRow
            tooltip={
              'This is the sum of monthly premium, estimated monthly doctor costs and estimated monthly drug costs.'
            }
            title={'Estimated average total cost per month'}
            values={getValuesByKey('monthlyCost', 'Not covered')}
          />
          <PlanDetailRow
            tooltip={
              "The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you're enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income."
            }
            title={
              <Typography className={'italic'} variant={'h5'}>
                <b>plus Medicare Part B Premium</b>
                <br />
                This is the standard Part B premium and may vary for some
                people.
              </Typography>
            }
            values={getValuesByKey('monthlyCost', 'Not covered', (v, plan) =>
              getDeductible('standardPartBMonthlyPremium', planYear)
            )}
          />
          {!!preferredDrugs?.length && (
            <tr>
              <td></td>
              {!!plans.length &&
                plans.map((plan) => (
                  <td key={plan.bidId}>
                    {!plan.missingFormularyInAep && (
                      <Button
                        variant={'outlined'}
                        size={'small'}
                        onClick={() => {
                          setPlan(plan);
                          setModalOpen(true);
                        }}
                      >
                        See monthly drug cost
                      </Button>
                    )}
                  </td>
                ))}
              <td></td>
            </tr>
          )}
          <tr className={'subheader'}>
            <td>
              <Subheader
                title={'Your Healthcare Selections'}
                img={'/assets/img/healthcare.svg'}
              />
            </td>
            <td colSpan={plans.length + 1}></td>
          </tr>
          <PlanDetailRow
            title={
              <>
                <Typography variant={'h5'} className={'bold'}>
                  Your doctors
                </Typography>
                <div className={'h-10'} />
                <Button
                  className={'w-130'}
                  variant={'outlined'}
                  size={'small'}
                  onClick={() =>
                    navigate(
                      paths.editPreferences + props.zip + '/' + props.countyName
                    )
                  }
                >
                  {getValuesByKey('doctorsCoverage', 0, (val) => val?.length)[0]
                    ? 'Edit'
                    : 'Add'}{' '}
                  doctors
                </Button>
              </>
            }
            values={getValuesByKey(
              'doctorsCoverage',
              'Varies by doctor',
              (v, plan) => (
                <DoctorCoverageList
                  isPPO={plan.planType === 'PPO'}
                  coverage={v}
                />
              )
            )}
          />

          <PlanDetailRow
            title={
              <>
                <Typography variant={'h5'} className={'bold'}>
                  Your drugs
                </Typography>
                <div className={'h-10'} />
                <Button
                  className={'w-130'}
                  variant={'outlined'}
                  size={'small'}
                  onClick={() =>
                    navigate(
                      paths.editPreferences +
                        props.zip +
                        '/' +
                        props.countyName +
                        '/drugs'
                    )
                  }
                >
                  {getValuesByKey('drugsCoverage', 0, (val) => val?.length)[0]
                    ? 'Edit'
                    : 'Add'}{' '}
                  drugs
                </Button>
              </>
            }
            values={getValuesByKey(
              'drugsCoverage',
              'Not covered',
              (value, plan) =>
                plan?.missingFormularyInAep ? (
                  <Typography className={'fs-12 card-title'}>
                    Speak to an Agent for 2023 drug details for this plan
                  </Typography>
                ) : (
                  <DrugCoverageList coverage={value} />
                )
            )}
          />

          <PlanDetailRow
            title={
              <>
                <Typography variant={'h5'} className={'bold'}>
                  Your pharmacy
                </Typography>
                <div className={'h-10'} />
                <Button
                  className={'w-130'}
                  variant={'outlined'}
                  size={'small'}
                  onClick={() =>
                    navigate(
                      paths.editPreferences +
                        props.zip +
                        '/' +
                        props.countyName +
                        '/pharmacy'
                    )
                  }
                >
                  {getValuesByKey(
                    'quoteDrugsTiers',
                    0,
                    (val) => val?.pharmacyName
                  )[0]
                    ? 'Edit'
                    : 'Add'}{' '}
                  pharmacy
                </Button>
              </>
            }
            values={getValuesByKey('quoteDrugsTiers', 'Not covered', (value) =>
              value?.pharmacyName ? (
                <Benefit
                  label={value?.pharmacyName}
                  value={true}
                  labelClass={'regular color-primary'}
                />
              ) : null
            )}
          />
          <tr className={'subheader'}>
            <td>
              <Subheader
                title={'Plan Highlights'}
                img={'/assets/img/medical-record-1.svg'}
              />
            </td>
            <td colSpan={plans.length + 1}></td>
          </tr>
          <PlanDetailRow
            title={'Plan Type'}
            tooltip={
              'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'
            }
            values={getValuesByKey('planType', 'Original Medicare')}
          />
          <PlanDetailRow
            title={'Monthly premium'}
            tooltip={
              'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'
            }
            values={getValuesByKey(
              'monthlyPremium',
              <div>
                <Typography variant={'h5'}>Part A: Typically $0.00</Typography>
                <Typography variant={'h5'}>
                  Part B: Typically{' '}
                  {getDeductible('standardPartBMonthlyPremium', planYear)}
                </Typography>
              </div>
            )}
          />
          <PlanDetailRow
            title={'Part B Giveback'}
            tooltip={
              'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'
            }
            values={getValuesByKey('partBAmount', 'No', (value) => (
              <Benefit
                labelClass={'regular lh-18'}
                label={value ? `${value} per month` : 'No'}
                value={!!value}
              />
            ))}
          />
          <PlanDetailRow
            title={'Annual in-network deductible'}
            tooltip={
              'The amount you must pay out of pocket for your health care from in-network medical services before your coverage begins to pay. '
            }
            values={getValuesByKey(
              'inNetworkDeductableAmount',
              <div>
                <Typography variant={'h5'}>
                  Part A:{' '}
                  {getDeductible('inpatientHospitalDeductible', planYear)}
                </Typography>
                <Typography variant={'h5'}>
                  Part B: {getDeductible('annualPartBDeductible', planYear)}
                </Typography>
              </div>,
              (value) =>
                plans.length
                  ? value || 'This plan does not have a deductible.'
                  : ''
            )}
          />
          <MultilinePlanDetailRow
            title={'Out-of-Pocket Maximum'}
            tooltip={
              'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'
            }
            values={getValuesByKey('outOfPocketMaximum', 'No Limit')}
          />
          <MultilinePlanDetailRow
            title={'Primary Doctor Visit'}
            tooltip={
              'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'
            }
            values={getValuesByKey('primaryCarePhysician', '20% of the cost')}
          />
          <MultilinePlanDetailRow
            title={'Specialist Visit'}
            tooltip={
              'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'
            }
            values={getValuesByKey('physicianSpecialist', '20% of the cost')}
          />
          <MultilinePlanDetailRow
            title={'Inpatient Hospital Care'}
            tooltip={
              'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'
            }
            values={getValuesByKey(
              'hospitalBenefits',
              <>
                <Typography className={'fs-12 lh-15'}>
                  <b>
                    {getDeductible('inpatientHospitalDeductible', planYear)}
                  </b>{' '}
                  deductible for each benefit period
                </Typography>

                <Li>
                  <b>Days 1-60:</b> $0 coinsurance for each benefit period
                </Li>
                <Li>
                  <b>Days 61-90:</b>{' '}
                  {getDeductible('dailyCoinsurance61_90', planYear)} coinsurance
                  per day of each benefit period
                </Li>
                <Li>
                  <b>Days 91 and beyond:</b>{' '}
                  {getDeductible('lifetimeReserveDays', planYear)} coinsurance
                  per each "lifetime reserve day" after day 90 for each benefit
                  period (up to 60 days over your lifetime)
                </Li>
                <Li>
                  <b>Beyond lifetime reserve days:</b> all costs
                </Li>
              </>,
              (value) => value || 'N/A'
            )}
          />
          <PlanDetailRow
            title={'Prescription Drug Coverage'}
            tooltip={
              'Part D (Prescription drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often bundled with Medicare Advantage plans.'
            }
            values={getValuesByKey('extraBenefits', 'Not covered', (val) =>
              val.drugCoverage ? 'Yes' : 'No'
            )}
          />
          <MultilinePlanDetailRow
            title={'Prescription Drug Deductible'}
            tooltip={
              'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay. '
            }
            values={getValuesByKey('drugDetails', 'Not applicable', (val) =>
              val?.mrxAltDedAmount || val?.mrxAltNoDedTier
                ? [val?.mrxAltDedAmount || '', val?.mrxAltNoDedTier || '']
                : 'No deductible'
            )}
          />
          <ServiceCoverageDetailRow
            scrollTo={'Extra Benefits'}
            tooltip={
              'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '
            }
            title={'Dental Services'}
            values={getValuesByKey('dental', 'Limited', (val) => val.benefits)}
          />
          <ServiceCoverageDetailRow
            scrollTo={'Extra Benefits'}
            tooltip={
              'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'
            }
            title={'Vision Services'}
            values={getValuesByKey('vision', 'Limited', (val) => val.benefits)}
          />
          <ServiceCoverageDetailRow
            scrollTo={'Extra Benefits'}
            tooltip={
              'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'
            }
            title={'Hearing Services'}
            values={getValuesByKey(
              'hearing',
              'Limited – covers diagnostic hearing and balance exams if doctor or other health care provider if you need medical treatment. Does not cover hearing aids or exams for fitting hearing aids.',
              (val) => val.benefits
            )}
          />
          <ServiceCoverageDetailRow
            scrollTo={'Extra Benefits'}
            tooltip={
              'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'
            }
            title={'Fitness Benefits'}
            values={getValuesByKey(
              'extraBenefits',
              'Not covered',
              (val) => val.fitness
            )}
          />
          <ServiceCoverageDetailRow
            scrollTo={'Extra Benefits'}
            title={'Insulin Savings'}
            tooltip={
              "Medicare Advantage plans that offer Insulin Savings are part of Medicare's Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit."
            }
            values={getValuesByKey(
              'extraBenefits',
              'Not covered',
              (val) => val.insulinSavings
            )}
          />
          <ServiceCoverageDetailRow
            scrollTo={'Extra Benefits'}
            title={'Over-the-counter Allowance'}
            tooltip={
              'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'
            }
            values={getValuesByKey('oTC', 'Not covered')}
          />
          <ServiceCoverageDetailRow
            scrollTo={'Extra Benefits'}
            tooltip={
              'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'
            }
            title={'Telehealth Services'}
            values={getValuesByKey(
              'extraBenefits',
              '20% of the cost after the Part B deductible',
              (val) => val.telehealth
            )}
          />
          <ServiceCoverageDetailRow
            scrollTo={'Extra Benefits'}
            tooltip={
              "Medicare Advantage plans often provide transportation benefits for no additional premium. For example coverage for transportation to or from doctor's appointments, pharmacies, or fitness centers."
            }
            title={'Transportation Services'}
            values={getValuesByKey('transportation', 'Not covered')}
          />
        </TableScrollElement>
        <TableScrollElement name={'Drug Coverage & Costs'}>
          <tr className={'header'}>
            <td style={{ top: (height || 0) + 41 }}>Drug Coverage & Costs</td>
            <td
              style={{ top: (height || 0) + 41 }}
              colSpan={plans.length + 1}
            ></td>
          </tr>
          <tr className={'subheader'}>
            <td>
              <Subheader
                title={'Estimated Monthly Cost Summary'}
                img={'/assets/img/healthcare_1.svg'}
              />
            </td>
            <td colSpan={plans.length + 1}></td>
          </tr>
          <tr className={'drug-subheader'}>
            <td>Deductible phase</td>
            <td colSpan={plans.length + 1}>
              You pay the full retail price of your drugs up until you meet the
              deductible level (if applicable).
            </td>
          </tr>
          <MultilinePlanDetailRow
            title={'Deductible amount'}
            values={getValuesByKey('drugDetails', 'Not applicable', (val) =>
              val?.mrxAltDedAmount && val?.mrxAltNoDedTier
                ? [val?.mrxAltDedAmount, val?.mrxAltNoDedTier]
                : '$0'
            )}
          />
          <tr className={'drug-subheader'}>
            <td>Initial Coverage phase</td>
            <td colSpan={plans.length + 1}>
              Initial coverage starts after you your deductible (if applicable).
              During this phase, your plan pays for a portion retail costs until
              the combined costs paid by you and your plan reaches{' '}
              {getDeductible('initialCoverageLimit', planYear)}.
            </td>
          </tr>
          {preferred30 && (
            <tr className={'drug-blue-subheader'}>
              <td>Preferred Retail Pharmacy cost-sharing (30 day supply)</td>
              <td colSpan={plans.length + 1}></td>
            </tr>
          )}
          {preferred30.map((tier, i) => (
            <MultilinePlanDetailRow
              title={tier.tierName}
              key={tier.tierName}
              tooltip={getInitialTooltipByTier(tier.tier)}
              values={getValuesByKey(
                'drugsTiers30Preferred',
                'Not covered',
                (val, plan) =>
                  val?.initialCoverageDrugTiers[i]?.coverage || 'n.a.'
              )}
            />
          ))}
          {standard30 && (
            <tr className={'drug-blue-subheader'}>
              <td>Standard retail cost-sharing (30 day supply)</td>
              <td colSpan={plans.length + 1}></td>
            </tr>
          )}
          {standard30.map((tier, i) => (
            <MultilinePlanDetailRow
              title={tier.tierName}
              key={tier.tierName}
              tooltip={getInitialTooltipByTier(tier.tier)}
              values={getValuesByKey(
                'drugsTiers30Standard',
                'Not covered',
                (val, plan) =>
                  val?.initialCoverageDrugTiers[i]?.coverage || 'n.a.'
              )}
            />
          ))}
          {preferred90 && (
            <tr className={'drug-blue-subheader'}>
              <td>Preferred mail-order cost-sharing (90 day supply)</td>
              <td colSpan={plans.length + 1}></td>
            </tr>
          )}
          {preferred90.map((tier, i) => (
            <MultilinePlanDetailRow
              title={tier.tierName}
              key={tier.tierName}
              tooltip={getInitialTooltipByTier(tier.tier)}
              values={getValuesByKey(
                'drugsTiers90Preferred',
                'Not covered',
                (val, plan) =>
                  val?.initialCoverageDrugTiers[i]?.coverage || 'n.a.'
              )}
            />
          ))}
          {standard90 && (
            <tr className={'drug-blue-subheader'}>
              <td>Standard mail-order cost-sharing (90 day supply)</td>
              <td colSpan={plans.length + 1}></td>
            </tr>
          )}
          {standard90.map((tier, i) => (
            <MultilinePlanDetailRow
              title={tier.tierName}
              key={tier.tierName}
              tooltip={getInitialTooltipByTier(tier.tier)}
              values={getValuesByKey(
                'drugsTiers90Standard',
                'Not covered',
                (val, plan) =>
                  val?.initialCoverageDrugTiers[i]?.coverage || 'n.a.'
              )}
            />
          ))}

          {!is2025 && <>
            <tr className={'drug-subheader'}>
              <td>Coverage Gap phase</td>
              <td colSpan={plans.length + 1}>
                The Coverage Gap starts after the total drug costs paid by you and
                the plan reach {getDeductible('initialCoverageLimit', planYear)}, up to the
                out-of-pocket maximum of{' '}
                {getDeductible('catastrophicCoverageLimit', planYear)}.
              </td>
            </tr>

            {preferred30 && (
              <>
                <tr className={'drug-blue-subheader'}>
                  <td>Preferred retail cost-sharing (30 day supply)</td>
                  <td colSpan={plans.length + 1}></td>
                </tr>
                {preferred30Gap.map((tier, i) => (
                  <MultilinePlanDetailRow
                    title={tier.tierName}
                    key={tier.tierName}
                    tooltip={getInitialTooltipByTier(tier.tier)}
                    values={getValuesByKey(
                      'drugsTiers30Preferred',
                      'Not covered',
                      (val, plan) =>
                        val?.coverageGapDrugTiers[i]?.coverage ||
                        !plan.extraBenefits.drugCoverage
                          ? 'n.a.'
                          : '25% coinsurance'
                    )}
                  />
                ))}
                <MultilinePlanDetailRow
                  title={`All ${preferred30Gap.length ? 'other' : 'drug'} tiers`}
                  tooltip={''}
                  values={getValuesByKey(
                    'drugsTiers90Standard',
                    'Not covered',
                    (val, plan) =>
                      !plan.extraBenefits.drugCoverage
                        ? 'n.a.'
                        : '25% coinsurance'
                  )}
                />
              </>
            )}

            {standard30 && (
              <>
                <tr className={'drug-blue-subheader'}>
                  <td>Standard retail cost-sharing (30 day supply)</td>
                  <td colSpan={plans.length + 1}></td>
                </tr>
                {standard30Gap.map((tier, i) => (
                  <MultilinePlanDetailRow
                    title={tier.tierName}
                    key={tier.tierName}
                    tooltip={getInitialTooltipByTier(tier.tier)}
                    values={getValuesByKey(
                      'drugsTiers30Standard',
                      'Not covered',
                      (val, plan) =>
                        val?.coverageGapDrugTiers[i]?.coverage ||
                        !plan.extraBenefits.drugCoverage
                          ? 'n.a.'
                          : '25% coinsurance'
                    )}
                  />
                ))}
                <MultilinePlanDetailRow
                  title={`All ${standard30Gap.length ? 'other' : 'drug'} tiers`}
                  tooltip={''}
                  values={getValuesByKey(
                    'drugsTiers90Standard',
                    'Not covered',
                    (val, plan) =>
                      !plan.extraBenefits.drugCoverage
                        ? 'n.a.'
                        : '25% coinsurance'
                  )}
                />
              </>
            )}

            {preferred90 && (
              <>
                <tr className={'drug-blue-subheader'}>
                  <td>Preferred mail-order cost-sharing (90 day supply)</td>
                  <td colSpan={plans.length + 1}></td>
                </tr>
                {preferred90Gap.map((tier, i) => (
                  <MultilinePlanDetailRow
                    title={tier.tierName}
                    key={tier.tierName}
                    tooltip={getInitialTooltipByTier(tier.tier)}
                    values={getValuesByKey(
                      'drugsTiers90Preferred',
                      'Not covered',
                      (val, plan) =>
                        val?.coverageGapDrugTiers[i]?.coverage ||
                        !plan.extraBenefits.drugCoverage
                          ? 'n.a.'
                          : '25% coinsurance'
                    )}
                  />
                ))}
                <MultilinePlanDetailRow
                  title={`All ${preferred90Gap.length ? 'other' : 'drug'} tiers`}
                  tooltip={''}
                  values={getValuesByKey(
                    'drugsTiers90Standard',
                    'Not covered',
                    (val, plan) =>
                      !plan.extraBenefits.drugCoverage
                        ? 'n.a.'
                        : '25% coinsurance'
                  )}
                />
              </>
            )}

            {standard90 && (
              <>
                <tr className={'drug-blue-subheader'}>
                  <td>Standard mail-order cost-sharing (90 day supply)</td>
                  <td colSpan={plans.length + 1}></td>
                </tr>
                {standard90Gap.map((tier, i) => (
                  <MultilinePlanDetailRow
                    title={tier.tierName}
                    key={tier.tierName}
                    tooltip={getInitialTooltipByTier(tier.tier)}
                    values={getValuesByKey(
                      'drugsTiers90Standard',
                      'Not covered',
                      (val, plan) =>
                        val?.coverageGapDrugTiers[i]?.coverage ||
                        !plan.extraBenefits.drugCoverage
                          ? 'n.a.'
                          : '25% coinsurance'
                    )}
                  />
                ))}
                <MultilinePlanDetailRow
                  title={`All ${standard90Gap.length ? 'other' : 'drug'} tiers`}
                  tooltip={''}
                  values={getValuesByKey(
                    'drugsTiers90Standard',
                    'Not covered',
                    (val, plan) =>
                      !plan.extraBenefits.drugCoverage
                        ? 'n.a.'
                        : '25% coinsurance'
                  )}
                />
              </>
            )}
          </>}

          <tr className={'drug-subheader'}>
            <td>Catastrophic Coverage phase</td>
            <td colSpan={plans.length + 1}>
              {plans[0]?.drugDetails?.catastrophicCoverageDescription}
            </td>
          </tr>
          {plans &&
            catastrophicCoverage.map((item: any, index) => (
              <MultilinePlanDetailRow
                key={item.title}
                title={item.title}
                tooltip={getGapTooltipByTier(
                  [DrugTierOutput.Generic, DrugTierOutput.NonPreferredBrand][
                    index
                  ]
                )}
                values={getValuesByKey(
                  'drugDetails',
                  'Not applicable',
                  (val) =>
                    val && val.catastrophicCoverage[0]?.items[index]?.values
                )}
              />
            ))}
        </TableScrollElement>
        <TableScrollElement name={'Medical & Hospital Coverage'}>
          <tr className={'header'}>
            <td style={{ top: (height || 0) + 41 }}>
              Medical & Hospital Coverage
            </td>
            <td
              style={{ top: (height || 0) + 41 }}
              colSpan={plans.length + 1}
            ></td>
          </tr>

          <tr className={'subheader'}>
            <td>
              <Subheader
                title={'Your Doctor Cost'}
                img={'/assets/img/doctor.svg'}
              />
            </td>
            <td colSpan={plans.length + 1}></td>
          </tr>

          <MultilinePlanDetailRow
            title={'Primary Doctor Visit'}
            tooltip={
              'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'
            }
            values={getValuesByKey('primaryCarePhysician', '20% of the cost')}
          />
          <MultilinePlanDetailRow
            title={'Specialist Visit'}
            tooltip={
              'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'
            }
            values={getValuesByKey('physicianSpecialist', '20% of the cost')}
          />
          <MultilinePlanDetailRow
            title={'Preventive Care'}
            tooltip={
              'Typically, periodic health exams that occur on a regular basis for preventive purposes, including routine physicals or annual check-ups.'
            }
            values={getValuesByKey('preventiveCare', '$0 copay')}
          />

          <tr className={'subheader'}>
            <td>
              <Subheader
                title={'Emergency, Hospital and Outpatient Coverage'}
                img={'/assets/img/healthcare.svg'}
              />
            </td>
            <td colSpan={plans.length + 1}></td>
          </tr>

          <PlanDetailRow
            title={'Emergency Room'}
            tooltip={
              "Typically, emergency room services include all services provided when a patient visits an emergency room for an emergency condition. An emergency condition is any medical situation that would lead most people to believe that failing to obtain immediate medical care could result in placing the patient's health at serious risk."
            }
            values={getValuesByKey(
              'hospitalityDetails',
              <div>
                <Typography variant={'h5'}>
                  You pay a copayment for each emergency department visit and a
                  copayment for each hospital service.
                </Typography>
                <Typography variant={'h5'} className={'mt-8'}>
                  You also pay 20% of the Medicare-approved amount for your
                  doctor's services, and the Part B deductible applies.
                </Typography>
                <Typography variant={'h5'} className={'mt-8'}>
                  If your doctor admits you to the same hospital for a related
                  condition within 3 days of your emergency department visit,
                  you don't pay the copayment because your visit is considered
                  part of your inpatient stay.
                </Typography>
              </div>,
              (v) => v.emergencyRoom
            )}
          />
          <PlanDetailRow
            title={'Urgently Needed Services'}
            tooltip={
              "Medical care that you get outside of your Medicare health plan's service area for a sudden illness or injury that needs attention but isn't life threatening. If it's not safe to wait until you get home to get care from a plan doctor, then the health plan must pay for the care."
            }
            values={getValuesByKey(
              'hospitalityDetails',
              '20% of the cost after the Part B deductible',
              (v) => v.urgentServices
            )}
          />
          <MultilinePlanDetailRow
            title={'Ambulance Services'}
            tooltip={
              'Transport by ambulance to and from your home and a medical facility to receive medical care. Medicare covers a number of different types of ambulance services depending on the seriousness of your medical condition, or whether other methods of transportation could endanger your health.'
            }
            values={getValuesByKey(
              'hospitalityDetails',
              '20% of the cost after the Part B deductible',
              (v) => v.ambulanceServices
            )}
          />
          <MultilinePlanDetailRow
            title={'Inpatient Hospital Care'}
            tooltip={
              'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'
            }
            values={getValuesByKey(
              'hospitalBenefits',
              <div>
                <Typography variant={'h5'}>
                  <b>
                    {getDeductible('inpatientHospitalDeductible', planYear)}
                  </b>{' '}
                  deductible for each benefit period
                </Typography>
                <Li>
                  <b>Days 1-60:</b> $0 coinsurance for each benefit period
                </Li>
                <Li>
                  <b>Days 61-90:</b>{' '}
                  {getDeductible('dailyCoinsurance61_90', planYear)} coinsurance
                  per day of each benefit period
                </Li>
                <Li>
                  <b>Days 91 and beyond:</b>{' '}
                  {getDeductible('lifetimeReserveDays', planYear)} coinsurance
                  per each "lifetime reserve day" after day 90 for each benefit
                  period (up to 60 days over your lifetime)
                </Li>
                <Li>
                  <b>Beyond lifetime reserve days:</b> all costs
                </Li>
              </div>,
              (value) => value || 'N/A'
            )}
          />
          <MultilinePlanDetailRow
            title={'Skilled Nursing Facilities (SNF)'}
            tooltip={
              'A nursing facility with the staff and equipment to give skilled nursing care and, in most cases, skilled rehabilitative services and other related health services.'
            }
            values={getValuesByKey(
              'skilledNursing',
              <div>
                <Li>
                  <b>Days 1–20:</b> $0 for each benefit period.
                </Li>
                <Li>
                  <b>Days 21–100:</b>{' '}
                  {getDeductible('skilledNursingFacility', planYear)}{' '}
                  coinsurance per day of each benefit period.
                </Li>
                <Li>
                  <b>Days 101 and beyond:</b> All costs.
                </Li>
              </div>
            )}
          />
          <MultilinePlanDetailRow
            title={'Outpatient Diagnostic Tests and Therapeutic Services'}
            tooltip={
              'Tests done to help a doctor diagnose or rule out a suspected illness, injury, or condition. These tests are ordered by your treating doctor and may include x-rays, blood tests, urinalysis, and tests done on tissue specimens.'
            }
            values={getValuesByKey(
              'outpatientHospitality',
              <div>
                <Typography variant={'h5'}>
                  Diagnostic radiology services (such as MRIs, CT scans): 20% of
                  the cost
                </Typography>
                <Typography className={'mt-8'} variant={'h5'}>
                  Diagnostic tests and procedures: 20% of the cost
                </Typography>
                <Typography className={'mt-8'} variant={'h5'}>
                  Lab services: You pay nothing
                </Typography>
                <Typography className={'mt-8'} variant={'h5'}>
                  Outpatient x-rays: 20% of the cost
                </Typography>
                <Typography className={'mt-8'} variant={'h5'}>
                  Therapeutic radiology services (such as radiation treatment
                  for cancer): 20% of the cost after the Part B deductible
                </Typography>
              </div>
            )}
          />
          <MultilinePlanDetailRow
            title={'Outpatient Hospital and Surgery'}
            tooltip={
              'Typically defined as any surgical procedure that does not require an overnight stay in a hospital.'
            }
            values={getValuesByKey(
              'outpatientSurgery',
              <div>
                <Typography variant={'h5'}>
                  Outpatient hospital: 20% of the cost after the Part B
                  deductible
                </Typography>
                <Typography className={'mt-8'} variant={'h5'}>
                  Ambulatory surgical center: 20% of the cost after the Part B
                  deductible
                </Typography>
              </div>
            )}
          />
          <MultilinePlanDetailRow
            title={'Outpatient Rehabilitation Services'}
            tooltip={
              'Services to help you regain abilities, such as speech or walking, that have been impaired by an illness or injury. These services take place in an outpatient setting. These services are provided by nurses, as well as by physical, occupational and speech therapists. Examples include working with a physical therapist to help you walk or working with an occupational therapist to help you get dressed.'
            }
            values={getValuesByKey(
              'outpatientRehabilitation',
              <div>
                <Typography variant={'h5'}>
                  Cardiac rehabilitation services including exercise, education,
                  and counseling: No cost
                </Typography>
                <Typography className={'mt-8'} variant={'h5'}>
                  Intensive cardiac rehabiliation in a doctor’s office: 20% of
                  the cost after the Part B deductible
                </Typography>
                <Typography className={'mt-8'} variant={'h5'}>
                  Occupational therapy: 20% of the cost after the Part B
                  deductible
                </Typography>
                <Typography className={'mt-8'} variant={'h5'}>
                  Physical therapy and speech and language therapy: 20% of the
                  cost after the Part B deductible
                </Typography>
              </div>
            )}
          />
          <MultilinePlanDetailRow
            title={'Outpatient Mental Health Care'}
            tooltip={
              'Typically for services provided by a mental health professional in an outpatient setting.'
            }
            values={getValuesByKey(
              'mentalHealth',
              <div>
                <Typography variant={'h5'}>
                  Annual depression screening: No cost
                </Typography>
                <Typography className={'mt-8'} variant={'h5'}>
                  Visits to your doctor or other health care provider to
                  diagnose or treat your condition: 20% of the cost after the
                  Part B deductible
                </Typography>
              </div>
            )}
          />
          <MultilinePlanDetailRow
            title={'Home Health Care'}
            tooltip={
              'Health care services and supplies that a doctor decides you may receive in your home under a doctor-established plan of care. Medicare only covers home health care on a limited basis as ordered by your doctor.'
            }
            values={getValuesByKey(
              'homeHealth',
              <div>
                <Typography
                  variant={'h5'}
                  color={'textPrimary'}
                  className={'mb-5'}
                >
                  You pay nothing if you meet the qualifying conditions.
                </Typography>
                <Typography
                  variant={'h5'}
                  color={'textPrimary'}
                  className={'mb-5'}
                >
                  Limitations and exclusions apply.
                  <br /> Medicare doesn't pay for:
                </Typography>
                <Typography variant={'h5'} color={'textPrimary'}>
                  <span className={'list-item'}>
                    24-hour-a-day care at your home
                  </span>
                </Typography>
                <Typography variant={'h5'} color={'textPrimary'}>
                  <span className={'list-item'}>
                    Meals delivered to your home
                  </span>
                </Typography>
                <Typography variant={'h5'} color={'textPrimary'}>
                  <span className={'list-item'}>
                    Homemaker services (like shopping, cleaning, and laundry)
                    that aren’t related to your care plan
                  </span>
                </Typography>
                <Typography variant={'h5'} color={'textPrimary'}>
                  <span className={'list-item'}>
                    Custodial or personal care that helps you with daily living
                    activities (like bathing, dressing, or using the bathroom),
                    when this is the only care you need
                  </span>
                </Typography>
              </div>
            )}
          />
        </TableScrollElement>
        <TableScrollElement name={'Extra Benefits'}>
          <tr className={'header'}>
            <td style={{ top: (height || 0) + 41 }}>Extra Benefits</td>
            <td
              style={{ top: (height || 0) + 41 }}
              colSpan={plans.length + 1}
            ></td>
          </tr>

          <tr className={'subheader'}>
            <td>
              <Subheader
                title={'Additional Benefits'}
                img={'/assets/img/teeth.svg'}
              />
            </td>
            <td colSpan={plans.length + 1}></td>
          </tr>

          <MultilinePlanDetailRow
            title={'Optional supplemental benefits'}
            tooltip={
              'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'
            }
            values={getValuesByKey(
              'optionalSupplementalBenefitsItems',
              'Not applicable'
            )}
          />
          <MultilinePlanDetailRow
            title={'Dental Services'}
            tooltip={
              'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '
            }
            values={getValuesByKey(
              'dental',
              "Limited.  Medicare Part A (Hospital Insurance) will pay for certain dental services that you get when you're in a hospital.",
              (val) => val.benefits
            )}
          />
          <MultilinePlanDetailRow
            title={'Vision Services'}
            tooltip={
              'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'
            }
            values={getValuesByKey(
              'vision',
              'Limited. Exam to diagnose and treat diseases and conditions of the eye (including yearly glaucoma screening): 20% of the cost after the Part B deductible Eyeglasses or contact lenses after cataract surgery: 20% of the cost after the Part B deductible',
              (val) => val.benefits
            )}
          />
          <MultilinePlanDetailRow
            title={'Hearing Services'}
            tooltip={
              'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'
            }
            values={getValuesByKey(
              'hearing',
              'Exam to diagnose and treat hearing and balance issues: 20% of the cost',
              (val) => val.benefits
            )}
          />
          <MultilinePlanDetailRow
            title={'Transportation Services'}
            tooltip={
              'Limited. Diagnostic hearing and balance exams: 20% of the cost after the Part B deductible'
            }
            values={getValuesByKey('transportation', 'Not applicable')}
          />
          <MultilinePlanDetailRow
            title={'Fitness Benefits'}
            tooltip={
              'Medicare Advantage plans often provide built-in fitness benefits or gym membership for no additional premium, for example gym memberships or fitness programs.'
            }
            values={getValuesByKey(
              'otherDefinedSupplemental',
              'No',
              (v) => v.fitnessBenefit
            )}
          />
          <MultilinePlanDetailRow
            title={'Insulin Savings'}
            tooltip={
              "Medicare Advantage plans that offer Insulin Savings are part of Medicare's Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit."
            }
            values={getValuesByKey('extraBenefits', 'No', (v) => (
              <Benefit label={''} value={!!v.insulinSavings} />
            ))}
          />
          <MultilinePlanDetailRow
            title={'Meals After Hospital Stay'}
            tooltip={
              'Healthy food or home deliveries for plan members post discharge from hospital or skilled nursing facility.'
            }
            values={getValuesByKey('meal', 'No')}
          />
          <MultilinePlanDetailRow
            title={'Over-the-Counter Allowance'}
            tooltip={
              'A periodical (such as monthly or quarterly) allowance for over-the-counter allowance pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'
            }
            values={getValuesByKey('oTC', 'No')}
          />
          <MultilinePlanDetailRow
            title={'Telehealth Services'}
            tooltip={
              'Medicare Advantage plans often provide telehealth benefits for no additional premium. These benefits can provide beneficiaries with more accessibility to medical and health-related services, through digital communications technologies, such as mobile phones, tablets and desktop computers.'
            }
            values={getValuesByKey(
              'telehealth',
              '20% of the cost  after the Part B deductible'
            )}
          />
        </TableScrollElement>
        <TableScrollElement name={'Plan Brochures'}>
          <tr className={'header'}>
            <td style={{ top: (height || 0) + 41 }}>Plan Brochures</td>
            <td
              style={{ top: (height || 0) + 41 }}
              colSpan={plans.length + 1}
            ></td>
          </tr>

          <tr className={'subheader'}>
            <td>
              <Subheader
                title={'Plan and Medicare Brochures'}
                img={'/assets/img/brochures.svg'}
              />
            </td>
            <td colSpan={plans.length + 1}></td>
          </tr>

          <MultilinePlanDetailRow
            title={'Plan Brochures'}
            values={getValuesByKey('planFiles', 'No', (value) =>
              planFiles(value).map((item) => (
                <Box
                  component={'a'}
                  sx={{
                    fontSize: 12,
                    color: 'rgba(28, 67, 79, 1)',
                    textDecoration: 'underline',
                  }}
                  target={'_blank'}
                  href={item.url}
                >
                  {item.label}
                </Box>
              ))
            )}
          />
          <PlanDetailRow
            title={'Medicare Brochures'}
            values={getValuesByKey('planFiles', 'No', (v, plan) => (
              <Box
                component={'a'}
                sx={{
                  fontSize: 12,
                  color: 'rgba(28, 67, 79, 1)',
                  textDecoration: 'underline',
                }}
                target={'_blank'}
                href={
                  'https://www.medicare.gov/Pubs/pdf/10050-Medicare-and-You.pdf'
                }
              >
                Medicare & You Handbook (
                {plans[0]?.planYear?.toString().replace('Year', '')})
              </Box>
            ))}
          />
        </TableScrollElement>
      </table>
    </Box>
  );
};

export default ComparisonTable;

const MultilinePlanDetailRow = (props: {
  title: string;
  values: any[];
  tooltip?: string;
}) => (
  <PlanDetailRow
    title={props.title}
    tooltip={props.tooltip}
    values={props.values.map((val) =>
      Array.isArray(val) ? <ListPlanDetailText content={val} /> : val
    )}
  />
);

const PlanDetailRow = (props: {
  title: string | JSX.Element;
  values: any[];
  className?: string;
  tooltip?: string;
}) => (
  <tr>
    <td>
      {!props.tooltip && (
        <>
          {typeof props.title === 'string' && (
            <Typography
              className={
                'bold fs-12 lh-15' +
                (props.className ? ' ' + props.className : '')
              }
            >
              {props.title}
            </Typography>
          )}
          {typeof props.title !== 'string' && props.title}
        </>
      )}
      {props.tooltip && (
        <TooltipedTitle
          infoPosition={'start'}
          title={props.title}
          className={
            'bold fs-12 lh-15' + props.className ? ' ' + props.className : ''
          }
          tooltip={props.tooltip}
        />
      )}
    </td>
    {props.values.map((value, index) => (
      <td key={index}>
        {typeof value === 'string' && (
          <Typography
            className={
              'fs-12 lh-15 ' + (props.className ? props.className : '')
            }
          >
            {value}
          </Typography>
        )}
        {typeof value !== 'string' && value}
      </td>
    ))}
  </tr>
);

const Subheader = (props: { title: string; img: string }) => (
  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
    <img className={'w-32 h-32'} src={props.img} />
    {props.title}
  </Box>
);

const ServiceCoverageDetailRow = styled(
  ({
    className,
    ...props
  }: {
    title: string;
    values: any[];
    scrollTo: string;
    className?: any;
    tooltip?: string;
  }) => (
    <PlanDetailRow
      title={props.title}
      tooltip={props.tooltip}
      values={props.values.map((val) =>
        Array.isArray(val) && !!val.filter((v) => !!v).length ? (
          <Link to={props.scrollTo} smooth={true} spy={true} duration={200}>
            <Benefit
              labelClass={className}
              value={true}
              label={'View details'}
            />
          </Link>
        ) : typeof val === 'string' ? (
          val
        ) : (
          <Benefit labelClass={className} value={val} label={''} />
        )
      )}
    />
  )
)({
  '&': {
    color: colors.custom.green.variant2,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: 12,
  },
});

const Li = (props: PropsWithChildren<any>) => (
  <Box sx={{ color: colors.text.primary }} className={'list-item fs-12 lh-15'}>
    {props.children}
  </Box>
);
