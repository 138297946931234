import React, { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import PlanListSort from './components/PlanListSort';
import { useParams } from 'react-router';
import * as QueryString from 'query-string';
import { Box, Button, FormControlLabel, Switch, Typography } from '@mui/material';
import { CRPreloader, withErrorBoundary } from '@coverright/ui/shared';

import { MarketplaceDisclamers } from '@coverright/ui/marketplaces';
import { PlanFilterContext } from './components/filters/PlanFilterContext';
import {
  areComponentPropsEqual, GetDefaultPlansYear,
  GetNextPlansYear,
  GetPlansYear,
  getQuoteId,
  useDebouncedEffect
} from '@coverright/utils';
import PlanCard from './components/PlanCard';
import PlanListEmailModal from './components/PlanListEmailModal';
import FiltersBadges from './components/filters/FiltersBadges';
import Messages from './components/Messages';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { MaPlanListQuery, useMaPlanListLazyQuery, useMaPlanListQuery } from '@coverright/data-access/medicare';
import { AppContext, FavoritesContext, QuoteContext } from '@coverright/shared/contexts';
import { DrugOutput, Field, PlanYear, Sort } from '@coverright/data-access/types/medicare';
import { MarketplaceLayoutContext } from '../../components/MarketplaceLayout';

type Plan = MaPlanListQuery['plans']['data'][0];


function PlanList({onPlansCountChange}: {onPlansCountChange: (count: number) => void}) {
  const pageSize = 4;
  const {zip, countyName: county} = useParams<{zip: string, countyName: string}>();

  const [sortBy, setSortBy] = React.useState<Field>(Field.MonthlyCost);
  const [plans, setPlans] = React.useState<Plan[]>([]);
  const [page, setPage] = React.useState({
    page: 0,
    size: pageSize
  });
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  //todo showAllPlans
  const {id, preferredDoctors, preferredDrugs, save} = React.useContext(QuoteContext);
  const {authenticated} = React.useContext(KeycloakContext);
  const {setDrawerOpen} = React.useContext(MarketplaceLayoutContext);
  const { enqueueSnackbar } = useSnackbar();
  const [{state: {plansShown}}] = React.useContext(AppContext);

  const [total, setTotal] = React.useState<number>();

  const sort = useMemo(() => {
    switch (sortBy) {
      case Field.Rating: return [
        { "field": Field.Rating,"direction":Sort.Desc },
        { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
        { "field": Field.Deductible, "direction": Sort.Asc},
        { "field": Field.Name, "direction": Sort.Asc}
      ]; break;
      case Field.DoctorsCoverage: return [
        { "field": Field.DoctorsCoverage,"direction":Sort.Desc },
        { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
        { "field": Field.Deductible, "direction": Sort.Asc},
        { "field": Field.Name, "direction": Sort.Asc}
      ]; break;
      default: return [
        { "field": Field.MonthlyCost,"direction":Sort.Asc },
        { "field": Field.Premium,"direction":Sort.Asc },
        { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
        { "field": Field.Deductible, "direction": Sort.Asc},
        { "field": Field.Name, "direction": Sort.Asc}
      ];
    }
  }, [sortBy]);


  const {values: filterValues, debouncedValues, switchZip, switchYear} = React.useContext(PlanFilterContext)

  const quoteId = id || getQuoteId();

  const results = useMaPlanListQuery({
    variables: {
      filter: {...filterValues!, showAllPlans: false},
      fullYear: filterValues?.planYear === GetNextPlansYear(),
      page,
      quoteId: quoteId || null,
      sort
    },

    skip: !filterValues
  })

  const [emailOpen, setEmailOpen] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0)

    const parsedQuery = QueryString.parse(document.location.search);

    if (parsedQuery.skipCreateAccount && parsedQuery.quote && !localStorage.getItem('praiseCardShown')) {
      localStorage.setItem('praiseCardShown', 'true');
    } else {
      showTour();
    }

    if (parsedQuery.skipCreateAccount) {
      localStorage.setItem('createAccountShown', 'true');
    }

    if (id && !authenticated && !localStorage.getItem('createAccountShown') && localStorage.getItem('showCreateAccountModal')) {
      setTimeout(() => {
        localStorage.setItem('createAccountShown', 'true');
        localStorage.removeItem('showCreateAccountModal');
        // setCreateAccountModalOpen(true)
      }, 7000)
    }

    localStorage.setItem('plansShown', 'true')
  }, []);

  const showTour = () => {
    if (!localStorage.getItem('tourShown') && !authenticated  && window.innerWidth > 1280) {
      localStorage.setItem('tourShown', 'true');
      // setIsTourOpen(true)
    }
  }

  const loadMore = React.useCallback(() => {
    setPage({
      ...page,
      page: page.page + 1
    })
  },[page]);

  useDebouncedEffect(() => {
    if (zip) {
      setPlans([]);
      setPage({
        page: 0,
        size: pageSize
      });
    //  window.scrollTo(0, 0)
    }
  }, 700, [filterValues, sortBy]);

  const hasMorePlans = React.useMemo(() => {
    return !total || plans.length < total;
  }, [total, plans.length])

  React.useEffect( () => {
    if(results.data) {
      setTotal(results.data.plans.totalElements)
      setPlans([...plans, ...filterElevancePlans(results.data.plans.data, filterValues?.companies)])
    }
  }, [results.data]);

  React.useEffect(() => {
    onPlansCountChange(total || 0)
  }, [total]);

  React.useEffect( () => {
    if(results.error) {
      enqueueSnackbar('Something wrong. Please try again later.', {variant: 'error'}); // todo add text
    }
  }, [results.error]);

  return <>
    <PlanListEmailModal open={emailOpen} id={quoteId as string} onClose={() => {
      setEmailOpen(false);
    }} />
    <Box  data-test={'ma_plan_list'} flexDirection={'column'} position={'relative'} maxWidth={'800px'} mt={2} alignItems={'center'}>
      <Button sx={{mb: 2, display: {lg: 'none'}}} fullWidth variant={'contained'} onClick={() => setDrawerOpen(true)}>Filter Results & Add Your Info</Button>
      <Messages total={total} plans={plans} loading={results.loading || !results.called}
                preferredDoctors={preferredDoctors}
                preferredDrugs={preferredDrugs}
                rating={debouncedValues?.rating}
                extraBenefits={debouncedValues?.extraBenefits}
                SNPTypes={debouncedValues?.SNPTypes}
                planTypes={debouncedValues?.planTypes}
                zip={zip}
                countyName={county}
      />      <Box>
        {/*<Box display={'flex'} alignItems={'center'} mb={4}>
          <Typography className={'fs-18 mt-16'} color={'textPrimary'}>
            Interested in <span className={'pointer'} onClick={goToMG} style={{borderBottom: '1px dotted ' + colors.text.primary}}>Medicare Supplement (Medigap) Insurance Plans</span>{/* or <span style={{borderBottom: '1px dotted ' + colors.text.primary}}>Prescription Drug (PDP)</span>*} plans instead?
          </Typography>
        </Box>*/}
        <PlanListSort onChange={setSortBy} favoritesCount={favorites.length} value={sortBy} zip={zip} county={county} />
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <FiltersBadges />
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Typography className={'mr-12'}>{new Date().getFullYear()}</Typography>
            <FormControlLabel control={<Switch checked={filterValues?.planYear === GetNextPlansYear()}
                                               onChange={e => switchYear(e.target.checked ? GetNextPlansYear() : GetPlansYear())}  />}
                              label={new Date().getFullYear() + 1} />
          </Box>
        </Box>

        <MemoizedList plans={plans}
                      plansLoaded={!results.loading && results.called}
                      show={!!filterValues}
                      favorites={favorites}
                      planYear={filterValues?.planYear || GetDefaultPlansYear()}
                      toggleFavorite={toggleFavorite}
                      authenticated={authenticated}
                      preferredDrugs={preferredDrugs}
                      zip={zip!}
                      county={county!} />
        {results.loading &&
          <Box sx={{
            width: '100%',
            position: 'absolute',
            top: 0,
            zIndex: 1,
            background: '#f5f7f6',
            minHeight: '100vh',
            height: '100%'
          }}>
            <Box sx={{ position: 'sticky', top: 'calc(50vh - 20px)' }}>
              <CRPreloader sx={{ margin: 'auto', width: '100px' }}/>
            </Box>
          </Box>
        }
        <LoadMorePlansButton onClick={loadMore} disabled={results.loading} show={hasMorePlans && !results.loading && plans.length > 0} />
      </Box>
      <MarketplaceDisclamers zip={zip} />
    </Box>
  </>
}

export default withErrorBoundary(PlanList);

const filterElevancePlans = (plans: Plan[], filterCompanies: string[] = []) => {
  if (filterCompanies.length && filterCompanies.some(c => elevanceCompanies.includes(c))) {
    return plans.filter(p => {
      const elevanceCompaniesFirstWords = elevanceCompanies.map(c => c.split(' ')[0]);
      const isElevancePlan = elevanceCompaniesFirstWords.some(name => p.parentOrgName?.toLowerCase().includes(name.toLowerCase()));
      if (!isElevancePlan) {
        return true;
      } else {
        const companyMatch = elevanceCompaniesFirstWords.find(name => p.parentOrgName?.toLowerCase().includes(name.toLowerCase()));
        return  filterCompanies.some(c => c.includes(companyMatch || ''));
      }
    })
  }
  return plans
}

const elevanceCompanies = [
  "Anthem Blue Cross and Blue Shield",
  "Anthem Blue Cross",
  "Healthy Blue",
  "Optimum HealthCare",
  "Wellpoint",
  "Simply",
  "Freedom Health",
  "HealthSun",
]

interface MemoizedListProps {
  plans: Plan[],
  plansLoaded: boolean,
  show: boolean,
  zip: string,
  county: string,
  planYear: PlanYear,
  preferredDrugs: DrugOutput[],
  authenticated?: boolean,
  favorites: string[],
  toggleFavorite: (bidId: string, zip: string) => void,
}

const MemoizedList = React.memo(({plans, plansLoaded, show, zip, county, planYear, authenticated, preferredDrugs, favorites, toggleFavorite}: MemoizedListProps) => {
  return <>
    {show && <>
      {!!plans.length && plans.map(plan => <PlanCard plan={plan}
                                                     authenticated={authenticated}
                                                     favorites={favorites}
                                                     toggleFavorite={toggleFavorite}
                                                     zip={zip}
                                                     planYear={planYear}
                                                     countyName={county}
                                                     preferredDrugs={preferredDrugs}
                                                     key={plan.bidId} />)}
      {(!plans.length && plansLoaded) && <Typography color={'textPrimary'}
                                                     align={'center'}
                                                     className={'fs-18 bold mt-50 mb-110'}>
        There are no plans available based on your search criteria. Try adjusting your filter settings to see more plans.
      </Typography>}
    </>}
  </>
}, areComponentPropsEqual)

const LoadMorePlansButton = React.memo(({show, onClick, disabled}: {show: boolean, disabled: boolean, onClick: any}) => <>
  {show && <Box textAlign={"center"} mt={'32px'}>
    <Button sx={{minWidth: 250}} variant={'outlined'} disabled={disabled} onClick={onClick}>Load More Plans</Button>
  </Box>}
</>)

